import {
  Point, MultiPoint, Polygon, MultiPolygon,
} from 'ol/geom';
import {
  Style, Stroke, Fill, Circle as CircleStyle,
} from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import ElementType from '@/modules/map/contracts/ElemetType';
// eslint-disable-next-line import/no-cycle
import { FieldMap } from '@/modules/field/types/Field';
import BasePolygonFeature from '@/modules/map/features/BasePolygonFeature';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { TypesStatusFields } from '@/modules/map/components/SettingsInstruments/types';

// let pattern = null;
//
// const cnv = document.createElement('canvas');
// const ctx = cnv.getContext('2d');
// const img = new Image();
// eslint-disable-next-line max-len
// img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAADlJREFUKFNjZCAAGJHkvRkYGLZC+XA2TAFIYAsDA4MPVAGMvRXdBJAECIAUgk0jWgHRVuB0JE7PAgD/pg4JEfK8SQAAAABJRU5ErkJggg==';
// img.onload = function () {
//   pattern = ctx.createPattern(img, 'repeat');
//   // layer.setStyle(new ol.style.Style({
//   //   fill: new ol.style.Fill({
//   //     color: pattern,
//   //   }),
//   // }));
// };

export default class FieldFeature extends BasePolygonFeature<FieldMap> {
  type = ElementType.Field;

  constructor(props: FieldMap) {
    super();
    if (props.id) {
      this.setId(props.id);
    }
    this.setProps(props);
    this.setAssayData(props?.analyses);
    this.setDefaultStyle();
    this.setGeometryFeature(props.geometry);
  }

  static borderStyle(feature) {
    feature.setStyle(new Style({
      stroke: new Stroke({
        color: '#CFD8DC50',
        width: 1,
      }),
      fill: new Fill({
        color: '#37474F77',
      }),
    }));
  }

  static modifyStyle(feature, fillColor = '#C8E6C920') {
    feature.setStyle((featureGeometry) => {
      switch (featureGeometry.getGeometry().getType()) {
        case 'Point': {
          return new Style({
            image: new CircleStyle({
              stroke: new Stroke({
                color: 'white',
                width: 1,
              }),
              fill: new Fill({
                color: '#388E3C',
              }),
              radius: 5,
            }),
            geometry: new Point(featureGeometry.getGeometry().getCoordinates()),
          });
        }
        case 'Polygon': {
          const coordinates = (featureGeometry.getGeometry() as Polygon).getCoordinates();
          return this.getPolygonModifyStyle(coordinates, 1, fillColor);
        }
        case 'MultiPolygon': {
          const coordinates = (featureGeometry.getGeometry() as MultiPolygon).getCoordinates();
          return this.getPolygonModifyStyle(coordinates, 2, fillColor);
        }
        default: return null;
      }
    });
  }

  private static getPolygonModifyStyle(
    coordinates: Coordinate[][] | Coordinate[][][],
    numberFlat: number,
    fillColor: string,
  ) {
    return [
      new Style({
        stroke: new Stroke({
          color: 'white',
          width: 3,
        }),
      }),

      new Style({
        stroke: new Stroke({
          color: '#388E3C',
          width: 1.5,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),

      new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
          fill: new Fill({
            color: '#388E3C',
          }),
          radius: 3,
        }),
        geometry: new MultiPoint(
          (coordinates as []).flat(numberFlat),
        ),
      }),
    ];
  }

  getLastDateAssay() {
    if (this.props.analyses?.date) {
      return moment(this.props.analyses.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return null;
  }

  private getDefaultStyle() {
    const { color } = this.props;
    const stroke = new Stroke({
      color: this.get('borderColor'),
      width: 2,
    });

    return [/* new Style({
      fill: new Fill({
        color,
      }),
      stroke,
    }), */new Style({
        stroke,
        fill: new Fill({
          color: `${color}40`,
        }),
      })];
  }

  setIsSelectedDate(status: boolean) {
    this.set('isSelected', status);
  }

  getIsSelectedDate(): boolean {
    return !!this.get('isSelected');
  }

  setDefaultStyle() {
    this.setIsSelectedDate(false);
    this.setStyle(
      ...this.getDefaultStyle(),
    );
  }

  setSelectStyle() {
    this.setIsSelectedDate(true);
    this.setStyle([
      new Style({
        stroke: new Stroke({
          color: 'white',
          width: 3,
        }),
      }),

      ...this.getDefaultStyle(),
    ]);
  }

  updateStyles() {
    if (this.getIsSelectedDate()) {
      this.setSelectStyle();
    } else {
      this.setDefaultStyle();
    }
  }

  private colors = {
    true: '#388E3C',
    false: '#fa8600',
    null: '#fa002e',
    empty: '#AAAAAA',
  }

  private statusAssay = {
    true: TypesStatusFields.Finished,
    false: TypesStatusFields.InProcess,
    null: TypesStatusFields.NotStarted,
    empty: TypesStatusFields.NotAnalysis,
  }

  getStatusAssay() {
    return this.get('statusAssay');
  }

  setStatusAssay(status: boolean | 'empty') {
    // @ts-ignore
    this.set('statusAssay', this.statusAssay[status]);
  }

  setAssayData(data: { date: string; isCollectionAnalyzesCompleted: boolean; }) {
    const status = data && data.date ? data.isCollectionAnalyzesCompleted : 'empty';

    // @ts-ignore
    this.set('borderColor', this.colors[status]);
    this.setStatusAssay(status);
  }
}
